import { applyMiddleware, compose, createStore, Store } from 'redux';
import createSagaMiddleware from 'redux-saga';

import { rootReducer, RootState } from './reducers';
import sagas from './sagas';

const sagaMiddleware = createSagaMiddleware();
const middlewares = [sagaMiddleware];

/**
 *
 */
export function configureStore(): Store<RootState> {
  const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

  const store: Store<RootState> = createStore(rootReducer, composeEnhancers(applyMiddleware(...middlewares)));
  sagaMiddleware.run(sagas);
  return store;
}
