import debug from 'debug';

import pjson from '../package.json';

const BASE = pjson.name;

enum LEVELS {
  TRACE = 'trace',
  INFO = 'info',
  WARN = 'warn',
  ERROR = 'error',
  FATAL = 'fatal',
}

const COLOURS = {
  trace: 'lightblue',
  info: 'blue',
  warn: 'orange',
  error: 'pink',
  fatal: 'red',
};

class Log {
  constructor() {
    // Always display this level of error
    debug.enable(`${BASE}:error`);
    debug.enable(`${BASE}:fatal`);

    debug.enable(`${BASE}*`);
  }

  generateMessage(level: LEVELS, message: string, source?: string): void {
    // Set the prefix which will cause debug to enable the message
    const namespace = `${BASE}:${level}`;
    const createDebug = debug(namespace);

    // Set the colour of the message based on the level
    createDebug.color = COLOURS[level];

    if (source) {
      createDebug(source, message);
    } else {
      createDebug(message);
    }
  }

  trace(message: string, source?: string): void {
    this.generateMessage(LEVELS.TRACE, message, source);
  }

  info(message: string, source?: string): void {
    this.generateMessage(LEVELS.INFO, message, source);
  }

  warn(message: string, source?: string): void {
    this.generateMessage(LEVELS.WARN, message, source);
  }

  error(message: string, source?: string): void {
    this.generateMessage(LEVELS.ERROR, message, source);
  }

  fatal(message: string, source?: string): void {
    this.generateMessage(LEVELS.FATAL, message, source);
  }
}

const log = new Log();
export default log;
