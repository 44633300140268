import { CompletedRunRecordInterface, RUN_FAIL_TYPE, RUN_STATUS } from '@asserted/models';
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import variables from 'assets/scss/app.scss';
import { capitalCase } from 'change-case';
import { round } from 'lodash';
import React from 'react';
import Chart from 'react-apexcharts';
import { connect } from 'react-redux';
import { RootState } from 'redux/reducers';

interface StateProps {}
interface DispatchProps {}
interface OwnProps {
  record: CompletedRunRecordInterface;
  height: number | string;
  width: number | string;
}

type ComponentProps = StateProps & DispatchProps & OwnProps;
interface ComponentState {}

class StatResultChart extends React.Component<ComponentProps, ComponentState> {
  render(): JSX.Element {
    const { record, height, width } = this.props;
    const { stats, status, failType } = record;

    const colors = [] as string[];
    const series = [] as { name: string; data: number[] }[];

    let statsUnknown = false;

    const failMessage = failType === RUN_FAIL_TYPE.TEST ? `${capitalCase(failType)} failure` : capitalCase(record?.failType || '');
    const statusString = status === RUN_STATUS.FAILED && failType ? failMessage : capitalCase(status);

    if (stats) {
      if (stats?.passes > 0) {
        series.push({
          name: 'Pass',
          data: [stats.passes],
        });
        colors.push(variables.success);
      }

      if (stats?.failures > 0) {
        series.push({
          name: 'Fail',
          data: [stats.failures],
        });
        colors.push(variables.danger);
      }

      if (stats?.incomplete > 0) {
        series.push({
          name: 'Incomplete',
          data: [stats.incomplete],
        });
        colors.push(variables.info);
      }
    }

    if (series.length === 0) {
      statsUnknown = true;
      series.push({
        name: statusString,
        data: [1],
      });
      colors.push(status === RUN_STATUS.FAILED ? variables.danger : variables.info);
    }

    const options = {
      chart: {
        sparkline: {
          enabled: true,
        },
        type: 'bar',
        stacked: true,
        toolbar: {
          show: false,
        },
        stackType: '100%',
      },
      plotOptions: {
        bar: {
          horizontal: true,
        },
      },
      colors,
      stroke: {
        width: 2,
        colors,
      },
      dataLabels: {
        enabled: true,
        formatter: (value, { seriesIndex, w }): string => {
          if (statsUnknown) return w.config.series[seriesIndex].name;
          return `${w.config.series[seriesIndex].name}:  ${round(value, 2)}%`;
        },
        // NOTE: for some reason, "colors" does not work, forcing it with "force-black-text"
      },
      fill: {
        opacity: 0.45,
      },
      tooltip: {
        enabled: !statsUnknown,
        theme: 'dark',
        x: {
          show: false,
        },
        followCursor: true,
      },
    };

    return <Chart options={options} series={series} type="bar" height={height} width={width} />;
  }
}

export default connect<StateProps, DispatchProps, OwnProps, RootState>(() => ({}))(StatResultChart);
