import { CompletedRunRecordInterface, TEST_EVENT_TYPES, TEST_RESULT_STATUS, TestErrorInterface } from '@asserted/models';
import { capitalCase } from 'change-case';
import { round } from 'lodash';
import { DateTime } from 'luxon';
import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Badge, Col, Row } from 'reactstrap';

import RunErrorCard from './RunErrorCard';
import StatResultChart from './StatResultChart';
import TestErrorCard from './TestErrorCard';

type ComponentProps = {
  record: CompletedRunRecordInterface;
  showErrors?: boolean;
  showTimes?: boolean;
  titleSize: string;
  chartWidth: string | number;
  className?: string;
};

interface ComponentState {}

class RunRecordContent extends React.Component<ComponentProps, ComponentState> {
  render(): JSX.Element {
    const { className, record, showErrors = false, showTimes = false, titleSize, chartWidth } = this.props;

    const { error: recordError } = record;
    const errors = record.results.filter(({ error }) => !!error).map(({ error, fullTitle }) => ({ ...error, fullTitle })) as TestErrorInterface[];

    const filteredResults = record.results.filter(
      ({ type }) => type !== TEST_EVENT_TYPES.EVENT_HOOK_END && type !== TEST_EVENT_TYPES.EVENT_HOOK_BEGIN
    );

    const columns = [
      {
        dataField: 'fullTitle',
        text: 'Test',
      },
      {
        dataField: 'duration',
        text: 'Duration',
        align: 'center',
        headerAlign: 'center',
        formatter: (duration): string => (duration === null ? '-' : `${duration} ms`),
      },
      {
        dataField: 'result',
        text: 'Result',
        align: 'center',
        headerAlign: 'center',
        formatter: (result): JSX.Element | null => {
          if (!result) return null;
          if (result === TEST_RESULT_STATUS.INCOMPLETE) return <Badge color="info">Incomplete</Badge>;
          return <Badge color={result === 'passed' ? 'success' : 'danger'}>{capitalCase(result || '')}</Badge>;
        },
      },
    ];

    return (
      <div className={className}>
        <Row>
          <Col>
            <div className="d-flex justify-content-between" style={{ fontSize: titleSize }}>
              <span style={{ fontSize: titleSize }}>{DateTime.fromJSDate(record.completedAt).toLocaleString(DateTime.DATETIME_MED)}</span>
              <span>
                <i className="mdi mdi-timer" /> {round((record.testDurationMs || 0) / 1000, 3)}s
              </span>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <StatResultChart record={record} height={50} width={chartWidth} />
          </Col>
        </Row>
        {showErrors ? (
          <>
            <Row className="mt-2">
              <Col>
                {errors?.map((error, index) => (
                  <TestErrorCard key={index} error={error} />
                ))}
              </Col>
            </Row>
            {recordError ? (
              <Row className="mt-2">
                <Col>
                  <RunErrorCard error={recordError} />
                </Col>
              </Row>
            ) : null}
          </>
        ) : null}
        {showTimes && filteredResults.length > 0 ? (
          <BootstrapTable bootstrap4 keyField="id" bordered data={filteredResults} columns={columns} hover classes="my-0" />
        ) : null}
      </div>
    );
  }
}

export default RunRecordContent;
