import { TestErrorInterface } from '@asserted/models';
import classNames from 'classnames';
import React from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';

type ComponentProps = {
  error: TestErrorInterface;
};

const TestErrorCard = (props: ComponentProps): JSX.Element => {
  const { error } = props;

  return (
    <Card className="nested-card">
      <CardHeader>{error.fullTitle}</CardHeader>
      <CardBody>
        <Row>
          <Col>
            <p className="text-danger">{error.message}</p>
          </Col>
        </Row>
        {error.diff ? (
          <Row>
            <Col>
              <span className="text-success">+ expected</span>
              <span className="text-danger"> - actual</span>
              <div className="mt-1 mb-2">
                {error.diff.split('\n').map((line) => {
                  const classes = [] as string[];

                  if (line.startsWith('+')) classes.push('text-success');
                  else if (line.startsWith('-')) classes.push('text-danger');

                  return <div className={classNames(classes)}>{line}</div>;
                })}
              </div>
            </Col>
          </Row>
        ) : null}
        {error.stack ? (
          <Row>
            <Col>
              {error.stack.split('\n').map((line, i, arr) => {
                const spanLine = (
                  // eslint-disable-next-line react/no-array-index-key
                  <div className="text-danger" key={i}>
                    {line}
                  </div>
                );

                if (i === arr.length - 1) {
                  return spanLine;
                }

                return [spanLine];
              })}
            </Col>
          </Row>
        ) : null}
      </CardBody>
    </Card>
  );
};

export default TestErrorCard;
