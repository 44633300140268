/* eslint-disable import/first,simple-import-sort/sort */
import React from 'react';
import Editor from 'react-simple-code-editor';
import { highlight, languages } from 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import Prism from 'prismjs';

Prism.highlightAll();

import { connect, DispatchProp } from 'react-redux';
import { Alert, Button, Card, CardBody } from 'reactstrap';

import RunRecordContent from '../components/RunRecordContent';
import { dismissAction, RecordsActions, RecordsStateInterface, runAction } from '../redux/records/actions';
import { RootState } from '../redux/reducers';
import { LoadingButton } from '../components/LoadingButton';

// import doesn't seem to work properly with parcel for jsx
require('prismjs/components/prism-jsx');

interface StateProps {
  records: RecordsStateInterface;
}
type DispatchProps = DispatchProp<RecordsActions>;
interface OwnProps {}

type ComponentProps = StateProps & DispatchProps & OwnProps;
interface ComponentState {
  code: string;
}

const DEFAULT_CODE = `const { expect } = require('chai');
const axios = require('axios');
const HTTP_STATUS = require('http-status');

describe('simple tests', () => {
  it('get endpoint returns 200', async () => {
    const { status } = await axios.get('https://api.asserted.io/v1/echo');
    expect(status).to.eql(HTTP_STATUS.OK);
  });

  it('post endpoint returns 200 and body', async () => {
    const { status, data } = await axios.post('https://api.asserted.io/v1/echo', { foo1: 'bar1', foo2: 'bar2' });
    expect(status).to.eql(HTTP_STATUS.OK);
    expect(data.body).to.eql({ foo1: 'bar1', foo2: 'bar2' });
  });

  it('intentionally failing test', async () => {
    const { status, data } = await axios.post('https://api.asserted.io/v1/echo?foo=bario', { something: 'exists' });
    expect(status).to.eql(HTTP_STATUS.OK);
    expect(data.body).to.eql({ something: 'doesnt exist' });
  });

  // ^^^ Edit and Run ^^^
});`;

class AssertedEditor extends React.Component<ComponentProps, ComponentState> {
  state: ComponentState = {
    code: DEFAULT_CODE,
  };

  runTest = () => {
    const { dispatch } = this.props;
    const { code } = this.state;

    dispatch(runAction(code));
  };

  dismissResults = () => {
    const { dispatch } = this.props;
    dispatch(dismissAction());
  };

  render(): JSX.Element {
    const {
      records: { record, error, loading },
    } = this.props;
    const { code } = this.state;

    return (
      <Card style={{ borderRadius: 0 }}>
        <CardBody>
          <Editor
            value={code}
            onValueChange={(_code) => this.setState({ code: _code })}
            highlight={(_code) => highlight(_code, languages.js)}
            padding={10}
            style={{
              fontFamily: '"Fira code", "Fira Mono", monospace',
              fontSize: 14,
              backgroundColor: '#1E2029',
              color: '#e3e4e8',
            }}
          />
          <div className="ml-2 mt-4">
            <LoadingButton size="lg" onClick={this.runTest} loading={loading}>
              Run Test
            </LoadingButton>
            {record || error ? (
              <Button size="lg" onClick={this.dismissResults} className="ml-4" color="light">
                Dismiss
              </Button>
            ) : null}
          </div>
          {error?.description ? (
            <Alert className="my-4" color="danger">
              <p>Syntax Error: {error.description}</p>
              <p>Line: {error.lineNumber}</p>
            </Alert>
          ) : null}
          {!error?.description && error?.message ? (
            <Alert className="my-4" color="danger">
              <p>Error: {error.message}</p>
            </Alert>
          ) : null}
          {record ? <RunRecordContent className="my-4" record={record} titleSize="25px" chartWidth="100%" showErrors showTimes /> : null}
        </CardBody>
      </Card>
    );
  }
}

export default connect<StateProps, DispatchProps, OwnProps, RootState>(({ records }) => ({ records }))(AssertedEditor);
/* eslint-enable import/first,simple-import-sort/sort */
