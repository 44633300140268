import { getApi } from './apiFactory';

// eslint-disable-next-line no-process-env
if (!process.env.REACT_APP_BASE_API_URL) {
  throw new Error('Base API URL not defined');
}

// eslint-disable-next-line no-process-env
const api = getApi(`${process.env.REACT_APP_BASE_API_URL}/v1`);
export default api;
