import './assets/scss/Saas.scss';

import React, { Component } from 'react';

import AssertedEditor from './pages/editor';

interface AppProps {}

/**
 * Main app component
 */
class App extends Component<AppProps> {
  render() {
    return <AssertedEditor />;
  }
}

export default App;
