import { store } from 'react-notifications-component';

export enum NOTIFY_TYPE {
  SUCCESS = 'success',
  ERROR = 'danger',
  INFO = 'info',
  WARN = 'warning',
}

export const notify = (title: string, message: string, type: NOTIFY_TYPE = NOTIFY_TYPE.INFO, durationMs = 10000) => {
  store.addNotification({
    title,
    message,
    type,
    className: type === NOTIFY_TYPE.WARN ? 'text-black-50' : undefined,
    container: 'top-right',
    dismiss: {
      duration: durationMs,
      onScreen: true,
      pauseOnHover: true,
    },
    insert: 'top',
    animationIn: ['animated', 'slideInRight', 'faster'],
    animationOut: ['animated', 'fadeOut', 'faster'],
  });
};
