import React from 'react';
import { Button, ButtonProps, Spinner } from 'reactstrap';

interface ComponentProps extends ButtonProps {
  loading?: boolean;
}

export class LoadingButton extends React.Component<ComponentProps> {
  render(): JSX.Element {
    const { children, loading, ...rest } = this.props;

    return (
      <Button disabled={loading} {...rest} className="d-inline-flex align-items-center">
        {children}
        {loading && <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="ml-1 my-auto" />}
      </Button>
    );
  }
}
