import React from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';

type ComponentProps = {
  error: string;
};

const RunErrorCard = (props: ComponentProps): JSX.Element => {
  const { error } = props;

  return (
    <Card className="nested-card">
      <CardHeader>Execution Error</CardHeader>
      <CardBody>
        <Row>
          <Col>
            <p className="text-danger">
              <pre>{error}</pre>
            </p>
          </Col>
        </Row>
      </CardBody>
    </Card>
  );
};

export default RunErrorCard;
