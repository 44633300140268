import { CompletedRunRecord, CompletedRunRecordInterface } from '@asserted/models';
import HTTP_STATUS from 'http-status';

import api from './api';
import { apiError, ApiErrorResponseInterface } from './errorHandler';

/**
 * @param {string} code
 */
export async function run(code: string): Promise<CompletedRunRecordInterface | ApiErrorResponseInterface> {
  return api
    .post('demo', { json: { code } })
    .then((response) => new CompletedRunRecord(response as any))
    .catch(apiError([HTTP_STATUS.NOT_ACCEPTABLE, HTTP_STATUS.BAD_REQUEST]));
}
