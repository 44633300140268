import HTTP_STATUS from 'http-status';
import { all, call, put, takeEvery } from 'redux-saga/effects';
import { run } from 'services/records';

import { RECORDS_ACTIONS, RunInterface, setStateAction } from './actions';

/**
 *
 */
export function* DISMISS() {
  yield put(setStateAction({ loading: false, record: null, error: null }));
}

/**
 * @param root0
 * @param root0.payload
 * @param root0.payload.code
 */
export function* RUN({ payload: { code } }: RunInterface) {
  yield put(setStateAction({ loading: true, error: null, record: null }));

  const { _error, ...response } = yield call(run, code);

  if (!_error) {
    yield put(setStateAction({ record: response }));
  } else if (_error.code === HTTP_STATUS.NOT_ACCEPTABLE) {
    yield put(setStateAction({ error: response }));
  } else {
    yield put(setStateAction({ error: { message: _error.message } }));
  }

  yield put(setStateAction({ loading: false }));
}

/**
 *
 */
export default function* rootSaga() {
  yield all([takeEvery(RECORDS_ACTIONS.DISMISS, DISMISS), takeEvery(RECORDS_ACTIONS.RUN, RUN)]);
}
