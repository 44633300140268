import { all } from 'redux-saga/effects';

import recordsSaga from './records/sagas';

/**
 *
 */
export default function* rootSaga(): any {
  yield all([recordsSaga()]);
}
