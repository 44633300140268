import ky, { Options } from 'ky';

/**
 * Extend KY with whatever hooks I can
 *
 * @param {string} prefixUrl
 * @returns {ky}
 */
export const clientWithPrefix = (prefixUrl): typeof ky =>
  ky.extend({
    prefixUrl,
    retry: 0,
  });

interface RequestMethod {
  <T = string>(url: string, options?: Options): Promise<{ [k: string]: any }>;
}

interface ApiInterface {
  get: RequestMethod;
  put: RequestMethod;
  delete: RequestMethod;
  patch: RequestMethod;
  head: RequestMethod;
  post: RequestMethod;
  ping: () => Promise<void>;
}

/**
 * Wrap GOT to process the response.
 * NOTE: There is probably a way to do this with handlers, but I couldn't figure it out and don't care anymore
 *
 * @param {string} prefixUrl
 * @returns {ApiInterface}
 */
export const getApi = (prefixUrl): ApiInterface => {
  const extendedClient = clientWithPrefix(prefixUrl);

  const processResponse = (response): { [k: string]: any } => (Array.isArray(response.data) ? { list: response.data } : response.data);

  return {
    get: async (...args) => {
      const response = await extendedClient.get(...args).json();
      return processResponse(response);
    },
    put: async (...args) => {
      const response = await extendedClient.put(...args).json();
      return processResponse(response);
    },
    delete: async (...args) => {
      const response = await extendedClient.delete(...args).json();
      return processResponse(response);
    },
    patch: async (...args) => {
      const response = await extendedClient.patch(...args).json();
      return processResponse(response);
    },
    head: async (...args) => {
      const response = await extendedClient.head(...args).json();
      return processResponse(response);
    },
    post: async (...args) => {
      const response = await extendedClient.post(...args).json();
      return processResponse(response);
    },
    ping: async () => {
      await extendedClient.get('health');
    },
  };
};
