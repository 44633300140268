import React from 'react';
import ReactDOM from 'react-dom';
import ReactNotification from 'react-notifications-component';
import { Provider } from 'react-redux';

import App from './App';
import { configureStore } from './redux/store';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(
  <>
    <ReactNotification />
    {/* <div className="invisible">force react notification to the top</div> */}
    <Provider store={configureStore()}>
      <App />
    </Provider>
  </>,
  document.querySelector('#asserted-editor')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
