import { RECORDS_ACTIONS, RecordsActions, RecordsStateInterface } from './actions';

export const initialState: RecordsStateInterface = {
  loading: false,
  error: null,
  record: null,
};

export default function reducer(state = initialState, action: RecordsActions): RecordsStateInterface {
  switch (action.type) {
    case RECORDS_ACTIONS.SET_STATE:
      return { ...state, ...action.payload };
    default:
      return state;
  }
}
