import Err from 'err';
import HTTP_STATUS from 'http-status';
import hdate from 'human-date';
import { DateTime } from 'luxon';

import log from '../log';
import { notify, NOTIFY_TYPE } from './notify';

export interface ApiErrorResponseInterface {
  [k: string]: any;
  _error: { code: number; message?: string; headers?: { [k: string]: any } };
}

export interface HTTPError extends Error {
  response: Response;
}

const isHttpError = (error: Error | HTTPError): error is HTTPError => !!(error as HTTPError).response;

/**
 * All API calls should return 2xx codes where possible.
 * For cases where that intentionally doesn't happen, the error code is appended to the response data (if any)
 *
 * Why not just do this in the axios handler? Because we need to be able to conditionally not notify for some calls
 *
 * @param {number[]} noNotifyCodes
 * @returns {Function}
 */
export function apiError(noNotifyCodes = [] as number[]): (error: Err | Error | HTTPError) => Promise<ApiErrorResponseInterface> {
  return async (error): Promise<ApiErrorResponseInterface> => {
    if (isHttpError(error)) {
      const { code, message, data } = await error.response?.json();
      // eslint-disable-next-line no-console
      console.log(data);
      if (noNotifyCodes.includes(code)) {
        return { ...data, _error: { code, message, headers: error.response.headers } };
      }

      const type = code >= HTTP_STATUS.INTERNAL_SERVER_ERROR || code === HTTP_STATUS.TOO_MANY_REQUESTS ? NOTIFY_TYPE.ERROR : NOTIFY_TYPE.WARN;

      let description = message || 'Unknown Error';

      if (code === HTTP_STATUS.TOO_MANY_REQUESTS && error.response.headers) {
        description = `${description}. Retry in ${hdate.relativeTime(DateTime.fromISO(error.response.headers['x-ratelimit-reset']).toJSDate())}`;
      }

      // eslint-disable-next-line quotes
      notify(code === HTTP_STATUS.TOO_MANY_REQUESTS ? "You're doing that too much" : HTTP_STATUS[code] || 'Failure', description, type, 10000);

      return { ...data, _error: { code, message } };
    }

    log.error(`UNEXPECTED ERROR TYPE: ${error.message}`);

    notify(error.status || 'Failure', error.message || 'Unknown Error', NOTIFY_TYPE.WARN, 10000);

    return { _error: { code: error.code || HTTP_STATUS.INTERNAL_SERVER_ERROR, message: error.message } };
  };
}

/**
 * @param {NOTIFY_TYPE} type
 * @returns {(error: Error | Err) => boolean}
 */
export function nonApiError(type: NOTIFY_TYPE = NOTIFY_TYPE.ERROR): (error: Error | Err) => boolean {
  return (error: Error | Err): boolean => {
    notify(error.code, error.message, type, 10000);
    return false;
  };
}
