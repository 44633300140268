import { CompletedRunRecord } from '@asserted/models';

export interface RecordsStateInterface {
  loading: boolean;
  record: CompletedRunRecord | null;
  error: {
    index?: number;
    lineNumber?: number;
    column?: number;
    description?: string;
    message?: string;
  } | null;
}

export enum RECORDS_ACTIONS {
  SET_STATE = 'records/SET_STATE',
  DISMISS = 'records/DISMISS',
  RUN = 'records/RUN',
}

export interface SetStateInterface {
  type: typeof RECORDS_ACTIONS.SET_STATE;
  payload: Partial<RecordsStateInterface>;
}
export const setStateAction = (state: Partial<RecordsStateInterface>): SetStateInterface => ({ type: RECORDS_ACTIONS.SET_STATE, payload: state });

export interface DismissInterface {
  type: typeof RECORDS_ACTIONS.DISMISS;
}
export const dismissAction = (): DismissInterface => ({
  type: RECORDS_ACTIONS.DISMISS,
});

export interface RunInterface {
  type: typeof RECORDS_ACTIONS.RUN;
  payload: {
    code: string;
  };
}
export const runAction = (code: string): RunInterface => ({ type: RECORDS_ACTIONS.RUN, payload: { code } });

export type RecordsActions = SetStateInterface | DismissInterface | RunInterface;
